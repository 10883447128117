<template>
  <div>
    <z-header></z-header>

    <div class="min_width banner_image_box">
      <img src="../assets/img_about us.png" alt="" />
    </div>

    <div class="section_box company_culture">
      <div class="page_center page_title">企业文化 · COMPANY CULTURE</div>
      <div class="page_center culture_box">
        <div class="culture_item">
          <img class="culture_image" src="../assets/img_culture_g.png" alt="" />
          <div class="title">理念</div>
          <p>服务为先 · 技术为本</p>
        </div>
        <div class="culture_item">
          <img class="culture_image" src="../assets/img_culture_f.png" alt="" />
          <div class="title">价值观</div>
          <p>简单、完美、负责、合作、诚信</p>
        </div>
        <div class="culture_item">
          <img
            class="culture_image"
            src="../assets/img_culture_f-1.png"
            alt=""
          />
          <div class="title">定位</div>
          <p>靠谱的互联网解决方案服务商</p>
        </div>
      </div>
      <div class="circular_icon circular_icon1"></div>
      <div class="circular_icon circular_icon2"></div>
    </div>

    <div class="section_box theam_culture">
      <div class="page_center page_title">团队文化 · TEAM CULTURE</div>
      <div class="page_center culture_box">
        <div class="culture_item">
          <img class="culture_image" src="../assets/img_culture_c.png" alt="" />
          <div class="culture_content right">
            <p class="title">专业</p>
            <p class="desc">
              我们的大部分成员拥有3年以上的软件开发技术背景和丰富的项目开发经验，技术核心骨干具备5年以上软件项目架构、项目管理、项目实施及后续的维护服务经验。
            </p>
          </div>
        </div>
        <div class="culture_item">
          <img class="culture_image" src="../assets/img_culture_a.png" alt="" />
          <div class="culture_content right">
            <p class="title">年轻</p>
            <p class="desc">
              我们的技术人员平均年龄仅有27岁，充满了朝气和创新精神。技术核心骨干均来自国内知名学府毕业，拥有专业的知识背景和扎实的技术功底。
            </p>
          </div>
        </div>
        <div class="culture_item">
          <div class="culture_content left">
            <p class="title">专注</p>
            <p class="desc">
              我们以匠心服务，专业致力客户的宗旨，在每个项目中都运用专业的项目管理知识及项目管理经验，为客户提供更高效的软件定制体验，成为受客户尊重和信赖的移动互联网应用系统开发商。
            </p>
          </div>
          <img class="culture_image" src="../assets/img_culture_d.png" alt="" />
        </div>
        <div class="culture_item">
          <div class="culture_content left">
            <p class="title">有梦想</p>
            <p class="desc">
              我们来自五湖四海，因为一个共同的梦想：做一家真正优秀的技术服务企业，为客户提供最可靠的互联网产品。
            </p>
          </div>
          <img class="culture_image" src="../assets/img_culture_b.png" alt="" />
        </div>
      </div>
    </div>

    <div class="section_box member-introduction">
      <div class="page_center page_title white">
        核心成员介绍 · INTRODUCTION OF CORE MEMBERS
      </div>
      <div class="page_center member_list">
        <div class="member_item">
          <div class="member_image">
            <img src="../assets/img_member_c.png" alt="">
          </div>
          <div class="member_content">
            <p class="member_name">夏双双/Tony</p>
            <p class="member_position">商务经理</p>
            <p class="member_desc">
              NIIT国际认证工程师，深圳科学思维研究会理事，深圳IT私享汇创始人之一，曾任职中软国际、兴盛优选（10亿美金独角兽）等知名互联网公司，参与过兴盛优选B2B网仓系统，Abida订货等系统，拥有日均单量800万单和年度GVM超100亿级别电商平台的项目管理经验。
            </p>
          </div>
        </div>
        <div class="member_item">
          <div class="member_image">
            <img src="../assets/img_member_a.png" alt="">
          </div>
          <div class="member_content">
            <p class="member_name">旷冶/Jone</p>
            <p class="member_position">产品负责人</p>
            <p class="member_desc">
              PMP国际认证工程师，深圳大学通信工程专业毕业，曾任职华为，参与过华为eFinance财经管理平台、电信海外项Basicframe等项目。对于客户的商业模式打造及市场策略有独到的见解，尤其擅长电商项目的架构和模式设计，并能够给客户提供有效的落地执行解决方案。
            </p>
          </div>
        </div>
        
        <div class="member_item">
          <div class="member_image">
            <img src="../assets/img_member_b.png" alt="">
          </div>
          <div class="member_content">
            <p class="member_name">黄宇健/Jack</p>
            <p class="member_position">技术负责人</p>
            <p class="member_desc">
              毕业于华南理工大学计算机专业，深圳移动互联网专家库备选人才，曾任职文思海辉、软通动力等知名IT技术方案公司，精通Java技术，分布式架构搭建等曾负责组织对接顺丰优选API、拍拍贷等项目开发和团队管理。擅长攻克项目的技术栈难题，有大型项目研发管理经验。
            </p>
          </div>
        </div>
        <div class="member_item">
          <div class="member_image">
            <img src="../assets/img_member_d.png" alt="">
          </div>
          <div class="member_content">
            <p class="member_name">珍珍/Jannie</p>
            <p class="member_position">视觉设计总监</p>
            <p class="member_desc">
              西安工程大学艺术设计专业硕士深圳大学继续教育学院设计专业高级讲师，红点奖获得者，曾任职EICO
              Design，多年视觉设计经验，精通UE/UI/VI视觉创意和交互设计，有独特的美学品味和深厚的设计功底，成功服务超过200家企业的方案，深得客户的信赖与认可。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section_box honor_certificate">
      <div class="page_center page_title">荣誉证书 · honor certificate</div>
      <div class="page_center certificate_list">
        <img
          src="../assets/ic_certificate_a.png"
          alt=""
          class="certificate_image"
        />
        <img
          src="../assets/ic_certificate_b.png"
          alt=""
          class="certificate_image"
        />
        <img
          src="../assets/ic_certificate_c.png"
          alt=""
          class="certificate_image"
        />
        <img
          src="../assets/ic_certificate_d.png"
          alt=""
          class="certificate_image"
        />
        <img
          src="../assets/ic_certificate_e.png"
          alt=""
          class="certificate_image"
        />
        <img
          src="../assets/ic_certificate_f.png"
          alt=""
          class="certificate_image"
        />
      </div>
    </div>
    <div class="section_box team_activities">
      <div class="page_center page_title">
        团队参与活动 · Team participation activities
      </div>
      <div class="page_center activities_box">
        <div class="activities_item">
          <img src="../assets/img_activity_a.png" alt="" />
        </div>
        <div class="activities_item activities_content bottom">
          <div class="title">团队参与研讨<br />—大数据与人工智能技术研讨会</div>
          <p class="desc">
            团队技术骨干参与大数据与人工智能技术研讨会，随着移动互联网的爆发，数据量呈现出指数级的增长，大数据的积累为人工智能提供了基础支撑。同时受益于计算机技术在数据采集、存储、计算等环节的突破，人工智能已从简单的算法+数据库发展演化到了机器学习+深度理解的状态。
          </p>
        </div>
        <div class="activities_item">
          <img src="../assets/img_activity_c.png" alt="" />
        </div>
        <div class="activities_item activities_content top">
          <div class="title">团队参与研讨<br />—大数据与人工智能技术研讨会</div>
          <p class="desc">
            2019年11月10日，由深圳九九创业科技有限公司（以下称“九九创业科技”）主办的“九九生态社区”平台启动仪式暨新闻发布会在深圳·万华国际大酒店隆重举行。发布会以“共建
            共享
            共赢”为主题，聚焦中国社区经济、生态社区战略发展和商业设计等当下热点，受到政府、行业及媒体的高度关注。
          </p>
        </div>
        <div class="activities_item">
          <img src="../assets/img_activity_b.png" alt="" />
        </div>
        <div class="activities_item activities_content top">
          <div class="title">团队参与研讨<br />—大数据与人工智能技术研讨会</div>
          <p class="desc">
            2019年11月19日下午，以"迎接新时代财富风口，成就互联网创富传奇"为主题的铭片-创富宝和九九同城APP正式上线发布会在深圳湾ECO国际会议中心隆重举行。本次发布会由九九铭信网络科技（北京）有限公司主办，深圳电视台著名节目主持人陶子现场主持，来自深圳企业界、媒体界的嘉宾参加了发布会。
          </p>
        </div>
      </div>
    </div>

    <z-contact></z-contact>
    <z-footer></z-footer>
  </div>
</template>

<script>
import zContact from "@/components/contact.vue";
export default {
  components: {
    zContact,
  },
};
</script>

<style lang="scss" scoped>
.banner_image_box {
  height: 685px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.section_box {
  padding-top: 80px;

  .description {
    margin-top: 40px;
    font-size: 14px;
    color: #333333;
    text-align: center;
    line-height: 28px;
  }
}

.page_title {
  font-size: 32px;
  color: #333333;
  text-align: center;
  &.white {
    color: #fff;
  }
}

.company_culture {
  position: relative;
  height: 525px;
  // background-image: url("../assets/bg_a.png");
  // background-size: cover;
  // background-position: right bottom;
  overflow: hidden;

  .culture_box {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
    font-size: 14px;
    color: #333333;
    text-align: center;
    .culture_image {
      width: 140px;
      height: 140px;
      margin-bottom: 40px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .circular_icon {
    z-index: 1;
    position: absolute;
    border: 80px solid #fbf9ff;
    border-radius: 50%;

    &.circular_icon1{
      width: 400px;
      height: 400px;
      left: 120px;
      bottom: 0;
      transform: translate(0,50%);
    }
    &.circular_icon2{
      width: 480px;
      height: 480px;
      right: 0;
      top: 0;
      transform: translate(50%,-50%);
    }
  }
  
}

.theam_culture {
  height: 882px;
  background-color: #fbf9ff;

  .culture_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px;
  }

  .culture_item {
    display: flex;
    width: 600px;
    height: 300px;
    color: #000000;

    .culture_image {
      width: 50%;
    }

    .culture_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 50%;
      font-size: 14px;
      line-height: 21px;

      .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 24px;
        text-align: center;
      }

      .desc {
        width: 200px;
        text-align: center;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }

      &.right::after {
        left: 0;
        transform: translate(-100%, -50%);
        border-right: 18px solid #fbf9ff;
      }

      &.left::after {
        right: 0;
        transform: translate(100%, -50%);
        border-left: 18px solid #fbf9ff;
      }
    }
  }
}

.member-introduction {
  height: 790px;
  background: url("../assets/bg_member.png") 100% 100%;

  .member_list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;

    .member_item {
      display: flex;
      width: 50%;

      &:nth-child(n + 3) {
        margin-top: 80px;
      }
    }

    .member_image {
      width: 100px;
      height: 100px;
      img{
        width: 100%;
        height: 100%;
      }
    }

    .member_content {
      padding-top: 19px;
      margin-left: 40px;
      color: #fff;
      font-size: 14px;
      line-height: 24px;

      .member_name {
        font-size: 18px;
      }
      .member_position {
        opacity: 0.4;
        margin-top: 20px;
      }
      .member_desc {
        width: 460px;
        margin-top: 40px;
      }
    }
  }
}

.honor_certificate {
  height: 1434px;

  .certificate_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 60px;
  }
  .certificate_image {
    width: 400px;
    height: 560px;
    margin-top: 20px;
  }
}

.team_activities {
  height: 1134px;
  background: #fbf9ff;
  .activities_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // width: 1278px;
    margin-top: 80px;
  }

  .activities_item {
    width: 426px;
    height: 426px;
    color: #000000;
    font-size: 14px;
    line-height: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .activities_content {
    .title {
      width: 306px;
      font-size: 20px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 24px;
    }

    .desc {
      width: 306px;
      text-indent: 2em;
      color: #333333;
    }

    &::after {
      content: "";
      z-index: 2;
      position: absolute;
      left: 50%;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    &.top::after {
      top: 0;
      transform: translate(-50%, -100%);
      border-bottom: 18px solid #fbf9ff;
    }

    &.bottom::after {
      bottom: 0;
      transform: translate(-50%, 100%);
      border-top: 18px solid #fbf9ff;
    }
  }
}
</style>